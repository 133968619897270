<template>
<div>   
        <base-card :items="sections" :md="12" :sm="12" :lg="12" :xl="12" :height="3">
        </base-card>
</div>
</template>

<script>
export default {
    data(){
        return {
            sections: [
      {
        title: "collection.indexes.people.title",        
        //text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        subtext: "collection.indexes.people.subtext",
        to: "/edition/browse/indexes/people",
        img: "/edition/indices/people.jpeg",
      },
      {
        title: "collection.indexes.places.title",
        //text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        subtext: "collection.indexes.places.subtext",
        to: "/edition/browse/indexes/places",
        img: "/edition/indices/places.png",
      },
      {
        title: "collection.indexes.org.title",
        //text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        subtext: "collection.indexes.org.subtext",
        to: "/edition/browse/indexes/organizations",
        img: "/edition/indices/organizations.jpeg",
      },
      {
        title: "collection.indexes.bib.title",
        //text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        subtext: "collection.indexes.bib.subtext",
        to: "/edition/browse/indexes/bibliography",
        img: "/edition/indices/bibliography.jpeg",
      },
    ],
        }
    }
}
</script>